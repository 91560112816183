<template>
  <AccountSharedLayout class="AccountForgotView">
    <span slot="title">{{ $t('title') }}</span>

    <template slot="main">
      <div class="instructions">
        {{ $t('instructions') }}
      </div>

      <form @submit.prevent="submit()">
        <FormGroup
          v-model="email"
          :label="$t('account.formLabels.email')"
          :v="$v.email"
          @input="reset()" />

        <div class="submitButtonContainer">
          <p
            v-if="success"
            v-t="'instructionsSent'"
            class="alert-success" />
          <p
            v-if="error"
            v-t="'error'"
            class="alert-danger" />
          <SubmitButton
            v-if="!success"
            class="submitButton"
            :label="$t('submit')"
            :working="working"
            :disabled="$v.$invalid" />
        </div>
      </form>

      <div class="links">
        <span v-t="'newUser'" />&nbsp;
        <router-link
          v-t="'signUpNow'"
          :to="{ name: 'AccountSignupView-basic' }"
          tabindex="-1" />
      </div>
    </template>
  </AccountSharedLayout>
</template>

<translations>
  title: 'Forgot your password?'
  title_no: 'Glemt passord?'

  instructions: 'Enter your email above and we will send you an email with instructions on how to reset your password.'
  instructions_no: 'Fyll inn din epost-adresse ovenfor og vi vil sende deg en epost med instruksjoner på hvordan du setter nytt passord.'

  instructionsSent: 'Email with instructions on how to reset your password has been sent. Please check your inbox and your spam folder.'
  instructionsSent_no: 'En epost med instruksjoner har blitt sendt til. Sjekk innboksen og evt. spam-folderen.'

  error: 'An error occured. Are you sure this is your email address?'
  error_no: 'En feil skjedde, er du sikker på at dette er riktig epost-adresse'
  submit: 'Reset my password'
  submit_no: 'Sett nytt passord'

  newUser: 'New user?'
  newUser_no: 'Ny bruker?'

  signUpNow: 'Sign up now.'
  signUpNow_no: 'Opprett ny konto nå.'
</translations>

<script>
import to from 'await-to-js';
import AccountSharedLayout from '../components/AccountSharedLayout';
import FormGroup from '../components/FormGroup';
import SubmitButton from '../components/SubmitButton';
import { required, email } from 'vuelidate/lib/validators';

export default {
    components: { AccountSharedLayout, FormGroup, SubmitButton },
    data() {
        return {
            email: '',
            working: false,

            error: false,
            success: false
        };
    },
    methods: {
        reset() {
            this.error = false;
            this.success = false;
        },
        async submit() {
            if (this.$v.$invalid) {
                return;
            }
            // this.working = true
            // try {
            //   await this.$store.dispatch('moduleAuth/requestPasswordReset', { email: this.email })
            // } catch (err) {
            //   this.error = true
            //   return
            // } finally {
            //   this.working = false
            // }
            // this.error = false
            // this.success = true
            this.working = true;
            let [err] = await to(this.$store.dispatch('moduleAuth/requestPasswordReset', { email: this.email }));
            this.working = false;
            if (err) {
                this.error = true;
                return;
            }
            this.error = false;
            this.success = true;
        }
    },
    validations: {
        email: {
            required,
            email
        }
    }
};
</script>

<style lang="scss" scoped>
.AccountForgotView {
}
.instructions {
    line-height: 1.5em;
    margin-bottom: 2em;
}
.submitButtonContainer {
    margin-top: 2em;
}
.submitButton {
    width: 100%;
}
.links {
    margin-top: 2em;
}

.alert-danger {
    background-color: #edc8c5;
    color: #c38a8a;
    padding: 1em;
}

.alert-success {
    background-color: #dff0d8;
    color: #3c763d;
    padding: 1em;
}
.links {
    a {
        text-decoration: none;
        color: #5fcf80;
    }
}
</style>
